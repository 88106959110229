import React from 'react'
import Img from 'gatsby-image'
import { MDBCol, MDBCard, MDBCardBody } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

class CardTeam extends React.Component {
  render() {
    return (
      <MDBCol md="6" lg="4" className="mt-2 mb-2">
        <MDBCard>
          <div className="leadercontainer">
            <Img
              fluid={this.props.image}
              alt={this.props.imageAltText}
              className="rounded-top"
            />
            {this.props.description && (
              <div className="leaderoverlay rounded-top">
                <div className="leadertext">{this.props.description}</div>
              </div>
            )}
          </div>
          <MDBCardBody>
            <div className="leadership-body">
              <h3 className="font-w-700 letter-spacing-1 text-xs-large text-medium mt-2 mb-2">
                {this.props.title}
              </h3>
              <h4 className="font-w-400 mb-3 text-medium">{this.props.subtitle}</h4>
            </div>
            <a
              href="https://www.linkedin.com/company/unicomengineering"
              target="_blank"
              className="linkedin"
              aria-label="LinkedIn"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faLinkedinIn} className="fa-2x mr-md-2" />
            </a>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default CardTeam
