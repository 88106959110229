import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import CardTeam from '../../../components/cardTeam'

export default ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <CardTeam
                  title={post.frontmatter.section[0].title}
                  subtitle={post.frontmatter.section[0].subtitle}
                  descrption=""
                  image={
                    post.frontmatter.section[0].image.childImageSharp.fluid
                  }
                  imageAltText={post.frontmatter.section[0].alttext}
                  titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium"
                  LinkedInurl={post.frontmatter.section[0].btnlink}
                />
                <MDBCol className="mt-5 mb-2 pl-4">
                  <p className="font-w-700 title-medium">
                    {post.frontmatter.section[0].title}
                  </p>
                  <p className="text-medium">{post.frontmatter.section[0].description}</p>
                  <Link
                    to="/about/corry-hong-founder-and-ceo/"
                    className="effect"
                  >
                    Read more about Corry Hong
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white pt-0">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <MDBRow>
                  {post.frontmatter.section.map((sections, index) => (
                    <>
                      {index > 0 && (
                        <CardTeam
                          title={sections.title}
                          subtitle={sections.subtitle}
                          description={sections.description}
                          image={sections.image.childImageSharp.fluid}
                          imageAltText={sections.alttext}
                          titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium"
                          LinkedInurl={sections.btnlink}
                        />
                      )}
                    </>
                  ))}
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "leadership" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alttext
          btnlink
        }
      }
      html
    }
  }
`
